<template>
  <div>
    <el-form>
      <el-row :gutter="50">
        <el-col :span="24">
          <div class="form-group" v-loading="loading">
            
            <el-form-item label="Content">
              <el-input
                type="textarea"
                v-model="field.content"
                placeholder="Enter content"
                rows="1"
                cols="20"
                :readOnly="true"
              ></el-input>
              <el-checkbox
              v-if="!isFromDocument && !getIsMobile"
              style="display: block"
              v-model="field.validations.vertical_line"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text=""
              inactive-text="Use as Horizontal line"
            >Use as vertical line
            </el-checkbox>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters} from "vuex";
export default {
  name: "templates-formComponents-HorizontalLine",
  components: {},
  props: [
    "field",
    "data",
    "isView",
    "isFromDocument",
    "allFields",
    "form",
    "workflowData",
    "dialogFormVisible",
  ],
  data() {
    return {
      loading: false,
      content: "<hr />",
    };
  },
  computed:{
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
  },
  mounted() {
    this.loading = true;

    this.field.content = this.content;

    this.loading = false;
  },
};
</script>

<style lang="scss"></style>
